.AccountsControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.AccountsControls button {
  margin-bottom: 9px;
}
.AccountCreateResults li {
  padding: 4px;
}
.AccountCreateResults li svg {
  vertical-align: middle;
}
.team-members-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}
.team-members-wrapper .member-modal {
  min-width: 50vw;
}
.team-members-wrapper tr,
.team-members-wrapper th,
.team-members-wrapper thead,
.team-members-wrapper tbody,
.team-members-wrapper td {
  vertical-align: middle;
}
.team-members-wrapper .member-edit {
  margin-bottom: 5px;
}
table tbody {
  word-break: break-word;
}
.table-menu-tooltip .tooltip-container {
  display: none;
}
.table-menu-tooltip:hover {
  position: relative;
  cursor: pointer;
  margin: 0 auto;
}
.table-menu-tooltip:hover .tooltip-container {
  display: block;
  position: absolute;
  left: -20rem;
  width: 24rem;
  border: 0.01rem solid #ccc;
  background: #fff;
  border-radius: 0.8rem;
  box-shadow: $box-shadow;
  z-index: 99;
}
.table-menu-tooltip:hover .tooltip-container .custom-tooltip {
  border-bottom: 0.01rem solid #e5e5e5;
  padding: 0.5rem 0.9rem;
  cursor: pointer;
  overflow: hidden;
}
.table-menu-tooltip:hover .tooltip-container .custom-tooltip:first-of-type {
  border-radius: 0.8rem 0.8rem 0 0;
}
.table-menu-tooltip:hover .tooltip-container .custom-tooltip:first-of-type::before {
  content: ' ';
  position: absolute;
  background-color: #fff;
  top: 0;
  right: 2.1rem;
  width: 0.8rem;
  height: 0.8rem;
  transform: rotate(45deg) translateY(calc(-50% - 3.3px));
  border-top: 0.1px solid #ccc;
  border-left: 0.1px solid #ccc;
  z-index: -10;
}
.table-menu-tooltip:hover .tooltip-container .custom-tooltip:last-of-type {
  border: none;
  border-radius: 0 0 0.8rem 0.8rem;
}
.app-container table.ItemDetails {
  margin: 0 auto;
}
.app-container table.ItemDetails thead td:nth-child(1),
.app-container table.ItemDetails tbody td:nth-child(1) {
  padding: 0.5em 1em;
  text-align: right;
  width: 30% !important;
  min-width: 8em;
}
.sites-table {
  min-width: 800px;
}
.sites-table div[class^='scrollTable__'] td .columns {
  justify-content: right;
}
.sites-table div[class^='scrollTable__'] td .columns button {
  padding: 8px;
}
.sites-table div[class^='scrollTable__'] td:nth-child(1),
.sites-table div[class^='scrollTable__'] th:nth-child(1) {
  width: 300px;
  word-break: keep-all;
}
.sites-table div[class^='scrollTable__'] td:nth-child(2),
.sites-table div[class^='scrollTable__'] th:nth-child(2) {
  width: 250px;
  word-break: keep-all;
}
.sites-table div[class^='scrollTable__'] td:nth-child(3),
.sites-table div[class^='scrollTable__'] th:nth-child(3) {
  width: 50px;
}
.sites-table div[class^='scrollTable__'] td:nth-child(3),
.sites-table div[class^='scrollTable__'] th:nth-child(3) {
  width: 70px;
}
.sites-table div[class^='scrollTable__'] td:nth-child(3),
.sites-table div[class^='scrollTable__'] th:nth-child(3) {
  width: 60px;
}
div[class^='modal__'] {
  height: 70%;
}
div[class^='modal__'] table.ItemDetails tbody {
  table-layout: auto;
  max-height: none;
}
label.SSHCertName label {
  margin-bottom: 20px;
}
div.SSHCertNameModal p {
  margin: 0 0 30px 0;
}
div.SSHCertNameModal .ButtonBar {
  margin-bottom: 0;
}
div.SSHCertNameModal button {
  height: 40px;
}
.Alerts {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: center;
  z-index: 10000;
}
.Alerts .Alert {
  padding: 1.2em;
  font-size: 100%;
  vertical-align: middle;
  box-shadow: 0 8px 16px -5px rgba(0,0,0,0.2);
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  transition: opacity 500ms ease-in-out;
}
.Alerts .Alert.hide {
  height: 0;
  opacity: 0;
}
.Alerts .Alert.show {
  height: 4em;
  opacity: 1;
}
.Alerts .Alert.Alert--info {
  background-color: #e1f0fa;
  color: #3498db;
}
.Alerts .Alert.Alert--warn {
  background-color: #fcf4e5;
  color: #eeb450;
}
.Alerts .Alert.Alert--error {
  background-color: #ffe5e5;
  color: #fe0000;
}
.Alerts .Alert.Alert--success {
  background-color: #e2f9da;
  color: #48bd1f;
}
.ButtonBar {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
}
.ButtonBar.centered {
  justify-content: center;
}
.ButtonBar.top-right {
  justify-content: flex-end;
}
.ButtonBar button.icon-solo {
  font-size: 24px;
  transition: 0.5s;
  background-color: transparent;
}
.ButtonBar button.icon-solo:hover > .svg-inline--fa {
  color: #3498db;
}
span.CloseButton {
  padding: 0.5em;
}
span.CloseButton:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.CopyableText svg {
  margin-left: 4px;
}
.CopyableText svg:hover {
  cursor: pointer;
}
.CopyableText:hover {
  color: #5197d6;
}
.CopyableText:focus {
  outline: none;
  transition: transform 0.2s ease-in-out;
}
.CopyableText.CopyableText--copied svg {
  transform: scale(1.5);
  color: #48bd1f;
}
.DefList dt {
  white-space: nowrap;
  float: left;
  clear: left;
  text-align: left;
  width: 95px;
}
.DefList dt::after {
  content: ':';
}
.DefList dd {
  margin-left: 100px;
  font-weight: bold;
  word-wrap: break-word;
}
.DefList.DefList-right-aligned dt {
  text-align: right;
}
.Field {
  margin-top: 1em;
  margin-bottom: 2em;
}
.FilePicker {
  margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FilePicker input[type=file] {
  display: none;
}
.FilePicker .FilePicker-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 1em;
}
.FormSet {
  margin-top: 1em;
}
.FormSet .FormSet-Form {
  display: flex;
  border: 1px solid #ccc;
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
.FormSet .FormSet-Form.block {
  flex-direction: column;
}
.FormSet .FormSet-Form.block > * {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.FormSet .FormSet-Form.inline {
  flex-direction: row;
}
.FormSet .FormSet-Form.inline > * {
  padding-right: 0.8em;
}
.FormSet .FormSet-Form span.FormSet-FormDeleteButton {
  color: #ccc;
  font-size: 20px;
  line-height: 38px;
  text-align: right;
}
.FormSet.isCollapsible .Collapsible__trigger {
  cursor: pointer;
}
.FormSet.isCollapsible .Collapsible__trigger.is-closed .FormSet-FormHeading::after {
  content: '+';
  float: right;
  font-size: 22px;
  font-weight: bold;
  line-height: 16px;
}
.FormSet.isCollapsible .Collapsible__trigger.is-open .FormSet-FormHeading::after {
  content: '-';
  float: right;
  font-size: 22px;
  font-weight: bold;
  line-height: 16px;
}
.FormSet.isCollapsible .FormSet-FormHeading {
  background-color: #e9e9e9;
  border-bottom: 1px solid #ccc;
}
.FormSet-FormHeading {
  font-weight: bold;
  text-align: center;
  padding: 1em;
}
.FormSet-add-button {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}
.HelpText {
  font-size: 12px;
  font-style: italic;
  padding: 0.5em 1em;
  overflow: auto;
}
.HelpText.error {
  color: #f00;
}
.HelpText p {
  margin-top: 0.2em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.HelpText.notify {
  color: #111;
  background: #f6c165;
  padding-top: 12px;
  margin-bottom: 10px;
  border-radius: 1em;
}
.HelpText.notify + .notify {
  margin-top: 12px;
}
.HelpText .helptext-has-icon {
  margin-bottom: 0;
}
.HelpText .helptext-has-icon svg {
  position: relative;
  top: -6px;
  display: inline-block;
  vertical-align: top;
}
.HelpText .helptext-has-icon span {
  width: calc(100% - 36px);
  display: inline-block;
  padding-left: 3px;
  vertical-align: top;
}
.HelpText .helptext-has-icon a {
  color: #02529d;
}
.ImproperValueWarning {
  display: flex;
  background-color: #fcf4e5;
  border: 1px solid #eeb450;
  border-radius: 4px;
  margin-top: 2px;
}
.ImproperValueWarning span {
  font-size: 12px;
  font-style: italic;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.ImproperValueWarning svg {
  margin: 4px 8px;
}
.passwordVerified {
  color: #48bd1f;
  background-color: #e2f9da;
  border: 1px solid #48bd1f;
  border-radius: 4px;
  padding: 0.5em;
  margin-bottom: 0.3em;
}
table.PickTable .CopyableText button {
  opacity: 0;
}
table.PickTable tr:hover .CopyableText button {
  opacity: 1;
}
table.PickTable tr.PickTable--picked {
  background-color: #e1f0fa !important;
}
table.PickTable tr.PickTable--picked:nth-child(even) {
  background-color: #f0f8fd !important;
}
.SearchForm {
  padding: 2em;
  position: relative;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  background-color: #fff;
}
.SearchResults .SearchResults-summary {
  padding: 0.5em 2em;
  text-align: center;
}
.SearchResults .SearchResults-cards {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.Wizard {
  display: flex;
}
.Wizard .WizardMenu {
  margin: 1.5em 1em;
}
.Wizard .WizardMenu div {
  color: #5197d6;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  width: 90%;
}
.Wizard .WizardMenu div svg {
  float: right;
}
.Wizard .WizardMenu div.complete {
  color: #008000;
}
.Wizard .WizardMenu div.error {
  color: #f00;
}
.Wizard .WizardMenu div.sectionHeading {
  padding-left: 1em;
}
.Wizard .WizardMenu div:hover {
  cursor: pointer;
  text-decoration: underline;
  background-color: #f9f9f9;
  border-radius: 1em;
}
.Wizard .WizardMenu div.active {
  background-color: #e9e9e9;
  border-radius: 1em;
}
.Wizard .WizardMenu div.unsaved {
  color: #3989d0;
  font-weight: bold;
}
.Wizard .WizardMenu div.disabled {
  color: #808080;
  cursor: not-allowed;
}
.Wizard .WizardStep {
  margin: 0;
}
.Wizard .WizardStep div[class^='section_'] {
  padding: 1em;
}
.Collapsible {
  vertical-align: top;
}
.Collapsible .Collapsible__trigger {
  width: 100%;
  position: relative;
  cursor: ns-resize;
}
html {
  background-color: #f1f1f1;
}
body {
  background-color: #f1f1f1;
  margin: 0;
}
b {
  font-weight: 700;
}
.flex-container {
  display: flex;
  align-items: flex-end;
}
.flex-container.start {
  align-items: start;
}
.flex-container.center {
  align-items: center;
  justify-content: center;
}
.flex-container.flex-space-between {
  justify-content: space-between;
}
.flex-fill {
  flex: 1 0 auto;
}
.flex-fluid {
  flex: 1 1 auto;
}
.flex-static {
  flex: 0 0 auto;
}
.text-center {
  text-align: center;
}
.text-error {
  color: #f00;
}
.columns {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.rows {
  display: flex;
  flex-direction: column;
}
section div[class^='sectionContent__'] {
  margin-top: 25px;
}
span[class^='radioGroup__'] {
  width: 100%;
}
span[class^='labelText__'] {
  font-size: 1em;
}
div[class^='section__'] {
  background-color: #fff;
}
div[class^='section__'].full-height {
  height: 100%;
}
div[class^='spinner__'].centered {
  top: 50%;
  left: 50%;
  margin: auto auto;
}
td[class*='td'][role='cell'] a {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #0861b5;
  text-decoration: none;
}
td[class*='td'][role='cell'] a:hover {
  text-decoration: underline;
}
.CopyEnvDiagram svg {
  vertical-align: middle;
}
.CopyEnvDiagram svg path {
  stroke-width: 3;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  height: 40px;
}
.Tabs-vertical {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.Tabs-vertical ul[class^='tabList_'] {
  display: flex;
  flex-direction: column;
  grid-column: 1/span 2;
  justify-content: flex-start;
  margin: 0;
}
.Tabs-vertical ul[class^='tabList_'] li {
  margin-left: 0;
  margin-right: 0;
}
.Tabs-vertical .Tabs-panels {
  grid-column: 4/span 9;
}
.rc-tooltip {
  font-size: 14px;
  max-width: 40%;
  opacity: 1;
  z-index: 9000;
}
.rc-tooltip-inner {
  background-color: #e5e5e5 !important;
  border: 1px #ccc solid;
  color: #111 !important;
}
.rc-tooltip-placement-top .rc-tooltip-inner {
  background-color: #ccc;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: #ccc;
}
.rc-tooltip-placement-right .rc-tooltip-inner {
  background-color: #ccc;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  border-right-color: #ccc;
}
.rc-tooltip-placement-bottom .rc-tooltip-inner {
  background-color: #ccc;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-bottom-color: #ccc;
}
.rc-tooltip-placement-left .rc-tooltip-inner {
  background-color: #ccc;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  border-left-color: #ccc;
}
.speedy {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}
.fadeIn30Right {
  -webkit-animation-name: fadeIn30Right;
  animation-name: fadeIn30Right;
}
.fadeIn30Left {
  -webkit-animation-name: fadeIn30Left;
  animation-name: fadeIn30Left;
}
.fadeOut30Right {
  -webkit-animation-name: fadeOut30Right;
  animation-name: fadeOut30Right;
}
.fadeOut30Left {
  -webkit-animation-name: fadeOut30Left;
  animation-name: fadeOut30Left;
}
@-moz-keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeIn30Right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeIn30Left {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@-webkit-keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@-o-keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@-moz-keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@-webkit-keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@-o-keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@keyframes fadeOut30Right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@-moz-keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@-webkit-keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@-o-keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@-moz-keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@-webkit-keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@-o-keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@keyframes fadeOut30Left {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
.UserHeroContainer {
  display: flex;
  max-width: 100% !important;
  margin-bottom: 1em;
  padding: 1em;
  color: #fff;
}
.UserHeroContainer .avatar {
  margin: 0 1em 0 75px;
}
.user-page-column {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.user-page-row {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  margin-bottom: 10px;
}
.user-page-row .pending {
  opacity: 0.6;
  cursor: pointer;
}
.user-page-row .denied {
  color: #f00 !important;
  cursor: pointer;
  text-decoration: line-through;
}
.user-label {
  font-size: 14px;
}
.user-page-container .button {
  width: 30%;
  margin-top: 10px;
}
.pending-tooltip {
  color: #000;
}
html,
body,
#root {
  height: 100%;
  background-color: #fff;
}
.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-container p {
  margin: 1.2em;
}
.app-container ul {
  margin: 1.2em;
}
.app-container ul.unstyled li {
  list-style: none;
}
.app-container ul li {
  margin: 0.4em 1em;
}
.app-container a,
.app-container .clickable:hover {
  color: #5197d6;
  text-decoration: underline;
}
.app-container div[class^='homepage'] > a,
.app-container a[class^='homepage'] {
  text-decoration: none;
  color: inherit;
}
.app-container .clickable {
  cursor: pointer;
}
.app-container code {
  font-family: monospace;
  font-style: normal;
  white-space: pre;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  margin: auto 2px;
  padding: 2px 6px;
}
.app-container code.block {
  display: block;
}
.app-container > div[class^='container__'] {
  max-width: 2000px;
}
.page-header {
  color: #111;
  margin-bottom: 1rem;
}
.page-header h1 {
  padding-top: 0;
  padding-bottom: 0;
  display: inline-block;
  flex: 1 0 auto;
}
.color-warn {
  background-color: #fcf4e5;
  color: #eeb450;
}
.color-error {
  background-color: #ffe5e5;
  color: #fe0000;
}
.color-success {
  background-color: #e2f9da;
  color: #48bd1f;
}
